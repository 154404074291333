import React, { memo } from "react";
import Head from "next/head";
import Script from 'next/script'
import AnniversaryTimer from "../AnniversaryTimer";
import HomepageBanner from "/components/Home/HomeSubComponents/HomepageBanner";
import RecentPurchase from "/components/Home/HomeSubComponents/RecentPurchase";
import ProductSec from "/components/Home/HomeSubComponents/ProductSec";
import VideoBanner from "/components/Home/HomeSubComponents/VideoBannerSection";
import TwoBannerSection from "/components/Home/HomeSubComponents/TwoBannerSection";
import OurCertificate from "/components/Home/HomeSubComponents/OurCertifications";
import { HomePageContext } from "/context/HomePageContext";
import { useHomepage } from "/lib/hooks/Homepage/useHomepage";
import styles from "./Home.module.css";

import ExploreMore from "./HomeSubComponents/ExploreMore";
import BelowBanner from "./HomeSubComponents/BelowBanner";
import CorporateGiftingSection from "./HomeSubComponents/CorporateGifting/CorporateGifingSection";
import ExploreAllIngredient from "./HomeSubComponents/ExploreAllIngredient";
import HomepageBlog from "./HomeSubComponents/HomepageBlog";
import Testimonials from "./HomeSubComponents/Testimonials";
import Link from "next/link";
import { useRouter } from "next/router";
import CategoryIcon from "../category/CategoryIcon";
interface HomeProps {
  cms_data: any; // Update the type to match your data structure
  homePageSeoData: any; // Update the type to match your data structure
  isSsrMobile: boolean;
  categoryIcon: any
}

const areAnniversaryTimerPropsEqual = (prevProps, nextProps) => {
  return prevProps.cms_data == nextProps.cms_data && prevProps.isMobileType == nextProps.isMobileType
}

const areHomepageBannerPropsEqual = (prevProps, nextProps) => {
  return prevProps.cms_data == nextProps.cms_data
}

const AnniversaryTimerMemoized = memo(AnniversaryTimer, areAnniversaryTimerPropsEqual)
const HomepageBannerMemoized = memo(HomepageBanner, areHomepageBannerPropsEqual);
const ProductSecMemoized = memo(ProductSec);
const VideoBannerMemoized = memo(VideoBanner);
const TwoBannerMemoized = memo(TwoBannerSection);

export const Home: React.FC<HomeProps> = ({ cms_data, homePageSeoData, isSsrMobile, categoryIcon }) => {
  const {
    isMobileType,
    value,
    homeSeo,
    width,
    banner,
    storeid,
    eventCommonObj,
    prefixUrl,
    testimonialList,
    session,
    sendBannerClickGAEvent,
    sendHeroBannerImpressionGAEvent,
    ProductSec1,
    productSection2,
    productSection3,
    videoBannerSec2,
    videoBannerSec3,
    awardWinningSection,
    ayurvedicTherapy,
    RecentPurchasec,
    ProductSec1View,
    productSection2View,
    productSection3View,
    videoBannerSec2View,
    videoBannerSec3View,
    awardWinningSectionView,
    ayurvedicTherapyView,
    RecentPurchaseView,
  } = useHomepage({ isSsrMobile, cms_data, homePageSeoData });

  return (
    <HomePageContext.Provider value={value}>
      <div className={styles.home}>
        <Head>
          {(homeSeo.meta_title) ? (<title>{homeSeo.meta_title}</title>) : null}
          {(homeSeo.meta_title) ? (<meta name="title" content={homeSeo.meta_title} />) : null}
          {(homeSeo.meta_description) ? (<meta name="description" content={homeSeo.meta_description} />) : null}
          {(homeSeo.meta_keywords) ? (<meta name="keywords" content={homeSeo.meta_keywords} />) : null}
          {(homeSeo.meta_title) ? (<meta property="og:title" content={homeSeo.meta_title} />) : null}
          {(homeSeo.meta_description) ? (<meta property="og:description" content={homeSeo.meta_description} />) : null}
          {(homeSeo.meta_image) ? (<meta property="og:image" content={homeSeo.meta_image} />) : null}
          {(homeSeo.meta_description) ? (<meta property="twitter:description" content={homeSeo.meta_description} />) : null}
          {(homeSeo.meta_title) ? (<meta property="twitter:title" content={homeSeo.meta_title} />) : null}
        </Head>

        {/* <Script
          async
          strategy="lazyOnload" src="https://rtg.l10.agency/resources/content/admit_kamaayuverda.js" integrity="sha384-ZFea/2gOlLb++GyhwQrGFQED8VIsWj9SO9VL1hr6acAUecKwzs8WI3M1VGWmb032" crossOrigin="anonymous" /> */}

        <Script src="https://static-cdn.trackier.com/js/trackier-websdk-init.js"/>

        {cms_data.anniversary_timerdatetime_timer_start_time && new Date(cms_data.anniversary_timerdatetime_timer_start_time) > new Date() ? (
          <AnniversaryTimerMemoized
            isMobileType={isMobileType}
            duration={cms_data.anniversary_timerdatetime_timer_start_time}
            timerMsg={cms_data.anniversary_timerdatetime_timer_msg}
            timerHomeImgUrl={cms_data.anniversary_timerdatetime_home_image_url}
            timerHomeMobImg={`${cms_data.base_media_url}homepage_images/${cms_data.anniversary_timerdatetime_home_mobile_image}`}
            timerHomeDeskImg={`${cms_data.base_media_url}homepage_images/${cms_data.anniversary_timerdatetime_home_desktop_image}`}
            timerHomeFont={cms_data.anniversary_timerdatetime_home_font_type}
            websiteID={storeid}
            styles={styles}
          />
        ) : null}

        <div style={{ minHeight: width ? (isMobileType ? (width / 0.73) : (width / 3.5)) : (isMobileType ? 965 : 527) }}>
          <div className={`swipe ${styles['swipe']}`}>
            <HomepageBannerMemoized
              sendBannerClickGAEvent={sendBannerClickGAEvent}
              sendHeroBannerImpressionGAEvent={sendHeroBannerImpressionGAEvent}
              banner={banner}
              cms_data={cms_data} />
          </div>
        </div>

        {cms_data?.enable_homepage_image_ticker ? (
          isMobileType ? (
            cms_data?.homepage_banner_image_ticker_mobile_image_redirect_url ? (
              <Link href={cms_data?.homepage_banner_image_ticker_mobile_image_redirect_url}>
                <img
                  className={styles.amexBanner}
                  loading='lazy'
                  src={`${cms_data.base_media_url}homepage_images/${cms_data?.homepage_banner_image_ticker_mobile_image}`}
                  alt={cms_data.homepage_banner_promotion12_title} title={cms_data.homepage_banner_promotion12_title}
                />
              </Link>
            ) : (
              <img
                className={styles.amexBanner}
                loading='lazy'
                src={`${cms_data.base_media_url}homepage_images/${cms_data?.homepage_banner_image_ticker_mobile_image}`}
                alt={cms_data.homepage_banner_promotion12_title} title={cms_data.homepage_banner_promotion12_title}
              />
            )
          ) : (
            cms_data?.homepage_banner_image_ticker_desktop_image_redirect_url ? (
              <Link href={cms_data?.homepage_banner_image_ticker_desktop_image_redirect_url}>
                <img
                  className={styles.amexBanner}
                  loading='lazy'
                  src={`${cms_data.base_media_url}homepage_images/${cms_data?.homepage_banner_image_ticker_desktop_image}`}
                  alt={cms_data.homepage_banner_promotion12_title} title={cms_data.homepage_banner_promotion12_title}
                />
              </Link>
            ) : (
              <img
                className={styles.amexBanner}
                loading='lazy'
                src={`${cms_data.base_media_url}homepage_images/${cms_data?.homepage_banner_image_ticker_desktop_image}`}
                alt={cms_data.homepage_banner_promotion12_title} title={cms_data.homepage_banner_promotion12_title}
              />
            )
          )
        ) : null}

        <div className="container">

          <div id="banner_9" className={styles.HomeCategoryIcon}>
            <CategoryIcon iconArr={categoryIcon} page='home' isMobileType={isMobileType} />
          </div>

          {/* Recent Purchase */}
          <div ref={RecentPurchasec}>
            <RecentPurchase
              inView={RecentPurchaseView}
              session={session}
              storeid={storeid}
            />
          </div>

          {/* Products 1 Section */}
          <div ref={ProductSec1} id="section_1" style={{ minHeight: isMobileType ? '37rem' : '38rem' }}>
            <ProductSecMemoized
              inView={ProductSec1View}
              storeid={storeid}
              mainCSSDiv='home_mobile_sec1'
              mainCSSDiv1='home_mobile_sec1'
              mainCSSDiv2='home_mobile_sec121'
              id={1}
              eventCommonObj={eventCommonObj}
            />
          </div>

          {/* Lively Spotlight */}
          <div className={styles.livelyContainer}>
            <div id='spotlightLively' className="render_lively_html_content" brand_id="7a94329b88" flow="h0kl3" wid_id="a41fcbc500" layout="866dcbb664" style={{ zIndex: "inherit" }}></div>
          </div>

          {/* Video Section and Banner */}
          <div id="banner_1" className='video-section-banner' ref={videoBannerSec2} style={{ minHeight: isMobileType ? '25rem' : '24rem' }}>
            <VideoBannerMemoized
              inView={videoBannerSec2View}
              sendBannerClickGAEvent={sendBannerClickGAEvent}
              mainCSS='home_mobile_sec2_sec2'
              normalCSS='home_mobile_sec2'
              id={1}
            />

          </div>

          {/* Products 2 Section */}
          <div id="section_2" className='product-section2' ref={productSection2} style={{ minHeight: isMobileType ? '37rem' : '38rem' }}>
            <ProductSecMemoized
              inView={productSection2View}
              storeid={storeid}
              mainCSSDiv=''
              mainCSSDiv1=''
              mainCSSDiv2='home_mobile_sec1'
              id={2}
              eventCommonObj={eventCommonObj}
            />
          </div>

          {/* Lively Carousel Home */}
          <div className={styles.livelyContainer}>
            <div id='carouselLively' className="render_lively_html_content" brand_id="7a94329b88" flow="h0kl3" wid_id="654f1e6c48" style={{ zIndex: "inherit" }}></div>
          </div>

          {/* Video Section and Banner 2*/}
          <div id="banner_2" className='video-section-banner2' ref={videoBannerSec3} style={{ minHeight: isMobileType ? '25rem' : '23rem' }}>
            <VideoBannerMemoized
              inView={videoBannerSec3View}
              sendBannerClickGAEvent={sendBannerClickGAEvent}
              mainCSS='home_mobile_sec3_sec3'
              normalCSS='home_mobile_sec3'
              id={2}
            />
          </div>

          {/* Award winning Section */}
          <div id="banner_3" className='award-winning-section' ref={awardWinningSection} style={{ minHeight: isMobileType ? '59rem' : '40rem' }}>
            <TwoBannerMemoized
              inView={awardWinningSectionView}
              sendBannerClickGAEvent={sendBannerClickGAEvent}
              id={3}
              mainCSS='home_mobile_sec4'
              mainCSS2=''
            />

          </div>

          {/* Products 3 Section */}
          <div id="section_3" className='product-section3' ref={productSection3} style={{ minHeight: '33rem' }}>
            <ProductSecMemoized
              inView={productSection3View}
              storeid={storeid}
              mainCSSDiv=''
              mainCSSDiv1='home_mobile_sec1'
              mainCSSDiv2='home_mobile_sec13'
              id={3}
              eventCommonObj={eventCommonObj}
            />
          </div>

          {/* AYURVEDIC HAIR THERAPY Section */}
          <div id="banner_10" className='ayurvedic-therapy-section' ref={ayurvedicTherapy} style={{ minHeight: isMobileType ? '57rem' : '40rem' }}>
            <TwoBannerMemoized
              inView={ayurvedicTherapyView}
              sendBannerClickGAEvent={sendBannerClickGAEvent}
              id={10}
              mainCSS='home_mobile_sec4'
              mainCSS2='home_mobile_sec14'
            />

          </div>

          {/* Explore more section */}
          {/* Commenting for now as we added icons on top of the page */}
          {/* <ExploreMore sendBannerClickGAEvent={sendBannerClickGAEvent} styles={styles} /> */}

          {/* 4 banner and button section*/}
          <BelowBanner styles={styles} eventCommonObj={eventCommonObj} />

          {/* Corporate gifting enquire now section */}
          <CorporateGiftingSection sendBannerClickGAEvent={sendBannerClickGAEvent} eventCommonObj={eventCommonObj} />

          {/* Explore All Ingredient section */}
          {/* <ExploreAllIngredient styles={styles} prefixUrl={prefixUrl} /> */}

          {/* Explore All Blogs section */}
          <HomepageBlog prefixUrl={prefixUrl} eventCommonObj={eventCommonObj} />

          {/* Testimonials */}
          {
            (testimonialList?.length > 0 ?
              <Testimonials styles={styles} testimonialList={testimonialList} /> : null
            )
          }

          {/* Our Certifications section */}
          {/* <OurCertificate /> */}
        </div>

        <div className="container">
          <div className={styles.home_mobile_sec11}>
            <p>Please beware of fraudulent messages and phone calls on behalf of Kama Ayurveda.</p>
            <p> We NEVER ask for bank details, OTPs, advance cash payments or engage in lotteries.</p>
          </div>
        </div>

        <div className={styles.leafSet}>
        {/* <img className={styles.leftLeaf} src="/images/leafLeft.png" loading="lazy" alt="leaf" /> */}
        <img className={`${styles['image-leaf']} ${styles['image-1440']} ${styles.leftLeaf}`} loading="lazy" src="/images/leftLeft.webp" alt="1440"  />
        {!isMobileType && <img className={`${styles['image-leaf']} ${styles['image-1920']} ${styles.leftLeaf}`} loading="lazy" src="/images/leafLeftLG.webp" alt="1920"  />}
        {!isMobileType && <img className={`${styles['image-leaf']} ${styles['image-2560']} ${styles.leftLeaf}`} loading="lazy" src="/images/leafLeftVLG.webp" alt="2560" />}
       
        <img className={`${styles['image-leaf']} ${styles['image-1440']} ${styles.rightLeaf}`} loading="lazy" src="/images/leafRight.webp" alt="1440"  />
        {!isMobileType && <img className={`${styles['image-leaf']} ${styles['image-1920']} ${styles.rightLeaf}`} loading="lazy" src="/images/leafRightlg.webp" alt="1920"  />}
        {!isMobileType && <img className={`${styles['image-leaf']} ${styles['image-2560']} ${styles.rightLeaf}`} loading="lazy" src="/images/leafRightVLG.webp" alt="2560" />}
        </div>

      </div>
    </HomePageContext.Provider >
  );
};
